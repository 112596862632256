  a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
  }

  /* 893d29 -> #212529 */
    /* 212529 -> #212529 */

    /* e2aaaa -> 911515*/
  .navbar-container{
    background-color: #212529;
  }

  .nav-link{
    text-align: center;
    margin-right: 15px;
  }

   .nav-item-active {
    background-color:#212529; 
    color: white; 
    border-bottom: 2px solid #911515;
  } 

  .nav-item:hover {
    background-color:#212529; 
    color: white; 
    border-bottom: 2px solid #911515;
  } 

   /* html {
    font-size: 14px;
  } */
  @media (min-width: 768px) {
    html {
      font-size: 16px;
    }
  }

  @media only screen and (max-device-width: 480px) {
      .nav-item{
        padding-left: 10px;
      }
  }
  
  .box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
  }
  